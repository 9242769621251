/* eslint-disable no-negated-condition */
import cloneDeep from 'lodash/cloneDeep';
import filter from 'lodash/filter';
import get from 'lodash/get';
import size from 'lodash/size';

import defaultFields from 'store/ManageGeneral/fields';
import { selectPreferences } from 'store/Preferences/selectors';
import { updatePreferences } from 'store/Preferences/actions';
import { selectParsedCustomAttributes } from 'store/CustomAttributes/selectors';
import { selectVulnsGeneralFields } from './selectors';

export function setFieldVisibility (field, show) {
  return async (dispatch, getState) => {
    let newManageTable = cloneDeep(selectVulnsGeneralFields(getState()));
    const column = get(newManageTable, field, null);

    const sortingFunc = (a, b) => {
      const aOrder = get(newManageTable, `${a}.order`, 0);
      const bOrder = get(newManageTable, `${b}.order`, 0);
      return aOrder - bOrder;
    };

    const enrichmentFields = ['cvss', 'cve', 'cwe'];
    const isEnrichmentField = enrichmentFields.includes(field);

    const enrichmentFieldsOrder = { cvss: 7, cve: 8, cwe: 9 };

    if (column) {
      const visibleKeys = Object
        .keys(newManageTable)
        .filter((key) => newManageTable[key].visible)
        .sort(sortingFunc);

      let fieldOrder;

      if (show) {
        if (isEnrichmentField) {
          fieldOrder = enrichmentFieldsOrder[field];
        } else {
          fieldOrder = visibleKeys.length + 1;
        }
      } else {
        let count = 1;
        visibleKeys.forEach((key) => {
          newManageTable[key].order = count;
          count += 1;
        });
        fieldOrder = -1;
      }

      newManageTable = {
        ...newManageTable,
        [field]: {
          ...column,
          order: fieldOrder,
          visible: show
        }
      };

      dispatch(updatePreferences({ manage_table: newManageTable }));
    }
  };
}

export function removeColumn (field) {
  return async (dispatch, getState) => {
    const manage_table = cloneDeep(selectVulnsGeneralFields(getState()));
    delete manage_table[field];
    dispatch(updatePreferences({ manage_table }));
  };
}

export function renameColumn (newColumn) {
  return async (dispatch, getState) => {
    const manage_table = cloneDeep(selectVulnsGeneralFields(getState()));
    let newManageTable = { ...manage_table };
    newManageTable = { ...newManageTable, [newColumn.newFieldName]: manage_table[newColumn.actualFieldName] };
    delete newManageTable[newColumn.actualFieldName];
    dispatch(updatePreferences({ manage_table: newManageTable }));
  };
}

export function setNewOrderColumns (columns) {
  return async (dispatch, getState) => {
    let manage_table = cloneDeep(selectVulnsGeneralFields(getState()));
    if (!manage_table) manage_table = { ...defaultFields };

    const fixedColumns = size(filter(manage_table, { locked: true }));

    columns.forEach((column) => {
      if (column) {
        if (!get(manage_table, `${column}.locked`, false)) {
          manage_table[column].order = columns.indexOf(column) + 1 + fixedColumns;
        }
      }
    });

    dispatch(updatePreferences({ manage_table }));
  };
}

export function setFieldWidth (field, value, tableName) {
  return async (dispatch, getState) => {
    const state = getState();
    const currentPreferences = selectPreferences(state);
    const currentTable = cloneDeep(selectVulnsGeneralFields(getState()));
    const preferences = cloneDeep(currentPreferences);
    const updatedTable = cloneDeep(currentTable);
    const defaultFields = selectVulnsGeneralFields(state);
    const currentField = { [field]: defaultFields[field] };

    if (!preferences[tableName]) preferences[tableName] = {};
    if (!updatedTable[field]) {
      currentField[field].maxWidth = value;
      currentField[field].minWidth = value;
      preferences[tableName] = { ...preferences[tableName], ...currentField };
    } else {
      updatedTable[field].maxWidth = value;
      updatedTable[field].minWidth = value;
      preferences[tableName] = updatedTable;
    }
    dispatch(updatePreferences(preferences));
  };
}

export function resetDefault () {
  return async (dispatch, getState) => {
    const fields = {
      ...defaultFields,
      ...selectParsedCustomAttributes(getState())
    };
    dispatch(updatePreferences({ manage_table: fields }));
  };
}
